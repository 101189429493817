import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDashboardData } from "../../actions/user";


const Dashboard = (props) => {
    document.title = "Home";
    const history = useHistory();
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(true);

    const getDashboardData = () => {
        let params = {
            token: props.auth.token,
        }
         
        setLoading(true);
        props.getDashboardData(params, res => {
            setLoading(false);
            if(res.status){
                if(res.data)
                  setDashboardData(res.data);
            }
            else {
                toast.error(res.message);    
            }
        },
        err => {
            setLoading(false);
            toast.error('Something went wrong!');
        });
      }

    useEffect(() => {
        getDashboardData();
    }, [])

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                            {/* <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to='/pos/dashboard'>Home</NavLink></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    {loading ?
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    :   
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{dashboardData.totalUserCount ? dashboardData.totalUserCount : 0}</h3>
                                        
                                        <p>Users</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div>
                                    <a onClick={() => {history.push('/user')}} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{dashboardData.totalCountLovedOne ? dashboardData.totalCountLovedOne : 0}</h3>
                                        <p>Loved Ones</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-heart" />
                                        
                                    </div>
                                    <a className="small-box-footer"><i className="fas " /></a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{dashboardData.careTeamsCount ? dashboardData.careTeamsCount : 0}</h3>
                                        <p>Care Teams</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-ios-people" />
                                    </div>
                                    <a className="small-box-footer"><i className="fas " /></a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>{dashboardData.carePoints ? dashboardData.carePoints : 0}</h3>
                                        <p>Care Points</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag" /> 
                                    </div>
                                    <a className="small-box-footer">
                                        <i className="fas " />
                                        </a>
                                </div>
                            </div>
                        </div>
                    }
                        
                    {/* /.row */}
                    {/* Main row */}
                    {/* <div className="row"> */}
                        {/* <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-header border-0">
                                                <div className="d-flex justify-content-between">
                                                    <h3 className="card-title">API Calls</h3>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <p className="d-flex flex-column">
                                                        <span className="text-bold text-lg">820</span>
                                                    </p>
                                                    <p className="ml-auto d-flex flex-column text-right">
                                                        <span className="text-success">
                                                            <i className="fas fa-arrow-up"></i> 12.5%
                                                        </span>
                                                        <span className="text-muted">Since last week</span>
                                                    </p>
                                                </div>


                                                <div className="position-relative mb-4">
                                                    <canvas id="visitors-chart" height="200"></canvas>
                                                </div>

                                                <div className="d-flex flex-row justify-content-end">
                                                    <span className="mr-2">
                                                        <i className="fas fa-square text-primary"></i> This Week
                                                    </span>

                                                    <span>
                                                        <i className="fas fa-square text-gray"></i> Last Week
                                                    </span>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-header border-0">
                                                <div className="d-flex justify-content-between">
                                                    <h3 className="card-title">Cumulative Transactions</h3>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <p className="ml-auto d-flex flex-column text-right">
                                                        <span className="text-success">
                                                            <i className="fas fa-arrow-up"></i> 33.1%
                                                        </span>
                                                        <span className="text-muted">Since last month</span>
                                                    </p>
                                                </div>


                                                <div className="position-relative mb-4">
                                                    <canvas id="sales-chart" height="200"></canvas>
                                                </div>

                                                <div className="d-flex flex-row justify-content-end">
                                                    <span className="mr-2">
                                                        <i className="fas fa-square text-primary"></i> This year
                                                    </span>

                                                    <span>
                                                        <i className="fas fa-square text-gray"></i> Last year
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div> */}
                    {/* </div> */}
                    {/* /.row (main row) */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
 });
const mapDispatchToProps = dispatch => ({
    getDashboardData: bindActionCreators(getDashboardData, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);