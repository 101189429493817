
import RestClient from '../../../utilities/RestClient';
import {API_URL, AUTH_API_URL} from '../../config'
import message from '../../constants/messages'

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {
    let responseMessage = defaultErrorMessage;
    let payload = response.payload;  // Data returned by API
    if (payload && Array.isArray(payload) && payload.length > 0) {
        responseMessage = payload[0];
    }
    else if (response.msg) {
        responseMessage = response.msg;
    }
    return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
    let res = {};
    if (result.status === 200 || result.status === 201){
      res = {
        status: true,
        message: responseMessage,
        statusCode: result.status,
        type: message.success,
        data: result.data.payload
      };
    } else {
      res = {
        status: false,
        message: responseMessage,
        type: message.error,
        statusCode: result.status
      };
    }
    return res;
}

/****** action creator for get All Resources ********/
export const getAllMedlist = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/medlists`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for get All conditions ********/
export const getAllConditions = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/conditions`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};


/****** action creator for add Resources ********/
export const addMedlist = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/medlists`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to add Resources. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
   };

/****** action creator for delete Resources ********/
export const deleteMedlist = (params, cb) => {
    const id = params.id;
       delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/medlists/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to delete Resources. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };

   /****** action creator for update resources ********/
export const updateMedlist = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/medlists/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to update resources. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };