import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from "../../actions/user";

const ResetPassword = (props) => {

    let { resetPasswordToken } = useParams();
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: ""
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({...formData, [name]: value });
        setErrorMessages({...errorMessages, [name]: "" });
    }

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
  
        if (!formData.password) {
          formIsValid = false;
          errors = {...errors, password: "Please enter new password."};
        } 
        else if(typeof formData.password !== "undefined"){
            var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/);
            if (!pattern.test(formData.password)) {
              formIsValid = false;
              errors = {...errors, password: "Password should have minimum eight characters, at least one capital letter, one small letter, one number and one special character."};
            }
        }
  
        if (!formData.confirmPassword) {
            formIsValid = false;
            errors = {...errors, confirmPassword: "Please enter confirm password."};
          } 
          else if(formData.password !== formData.confirmPassword){
            formIsValid = false;
            errors = {...errors, confirmPassword: "Password and confirm password don't match."};
          }
  
        setErrorMessages(errors);
        return formIsValid;
      }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(validateForm()){
            setLoading(true);

            let params = {
                type: 'email',
                password: formData.password,
                uuid: resetPasswordToken
            }

            props.resetPassword(params, res => {
                    setLoading(false);
                    if(res.status){
                        toast.success(res.message);
                        history.push('/login');
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }
        
    };

    useEffect(() => {
        // 
    }, []);

    return (
        <>
            <div className="card-header text-center">
                <h3 className='mb-0'><b>Reset</b> Password</h3>
            </div>
            <div className="card-body login-card-body">
                <p className="login-box-msg">Enter new password below to reset your previous password</p>
                <form onSubmit={(e) => {handleSubmit(e)}}>
                    <div className="input-group mb-3">
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder='New Password'
                            value={formData.password}
                            onChange={(e) => {handleChange(e)}}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-lock"></span>
                            </div>
                        </div>
                        <span className="error invalid-feedback">{errorMessages.password}</span>
                    </div>

                    <div className="input-group mb-3">
                        <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            placeholder='Confirm New Password'
                            value={formData.confirmPassword}
                            onChange={(e) => {handleChange(e)}}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-lock"></span>
                            </div>
                        </div>
                        <span className="error invalid-feedback">{errorMessages.confirmPassword}</span>
                    </div>

                    <div className="row">
                        <div className="col-7">
                            <a onClick={() => {history.push('/login')}}>Back to Login?</a>
                        </div>
                        
                        <div className="col-5">
                            <button className="btn btn-primary btn-block" disabled={loading} type="submit">
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Sign In</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    // userLogin: () => dispatch(userLogin())
    resetPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);