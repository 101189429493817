import React, { useState,useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { updateRoleBox } from '../../actions/lockBoxes';
import Button from 'react-bootstrap/Button';

const EditCareRole = (props) => {
    const selectedLockBox = props.selectedLockBox;
    const [lockBoxName, setLockBoxName] = useState(selectedLockBox ? selectedLockBox.name : "");
    const [description, setDescription] = useState(selectedLockBox ? selectedLockBox.description : "");
    const [loading, setLoading] = useState(false);


    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "lockBoxName") {
            setLockBoxName(value.trimStart());
        }
        else if (fieldName === "description") {
            setDescription(value.trimStart());
        }
      };
    
    //edit lock box 
    const handleUpdate = () => {
        if (!lockBoxName) {
            toast.error("Please enter lock Box Name ");
            return;
            } 
            else if (!description) {
            toast.error("Please enter description");
            return;
        }

        let params = {
            token: props.auth.token,
            name: lockBoxName,
            description : description,
            selectedLockBoxId: selectedLockBox ? selectedLockBox.id : "" ,
        }
        setLoading(true);
        props.updateRoleBox(params, res => {
            setLoading(false);
          if( res.status){
             toast.success(res.message);
             props.refreshLockBox();
             props.closeModal();
          }
          else {
            toast.error(res.message);
          }
            },
            err => {
            setLoading(false);
            toast.error("Something went wrong!");
        })
    
    };

    useEffect(() => {
        //
      }, [])

    return (
        <> 
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Name</label>
                        <input className="form-control" id="inputName" type="text" value={lockBoxName} placeholder="Name"
                        
                            name="lockbox Name" required="" onChange={(e) => {onChangeInput(e, 'lockBoxName')}} />
                    </div>
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Description</label>
                        <input className="form-control" id="inputName" type="text" value={description} placeholder="Description"
                        
                            name="description" required="" onChange={(e) => {onChangeInput(e, 'description')}} />
                    </div>
                    <div className="form-group text-center col-md-12 mt-3 mb-3">
                        <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                            <span>Close</span>
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-primary" disabled={loading} type="button" onClick={() =>{handleUpdate()}}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save Changes</span>
                        </button>
                </div>
            </form>
         
        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateRoleBox: bindActionCreators(updateRoleBox, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditCareRole);
