import React, { useState,useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { updateCategoriesTypes } from "../../actions/categories";


const EditCategories = (props) => {
    const selectedCategoriesTypes = props.selectedCategoriesTypes;
    const [categorieTypesName, setCategorieTypesName] = useState(selectedCategoriesTypes ? selectedCategoriesTypes.name : "");
    const [loading, setLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "CategoriesTypesName") {
            setCategorieTypesName(value.trimStart());
        }
      };
    
 
    const handleUpdate = () => {
        if (!categorieTypesName) {
            toast.error("Please enter categorie Name");
            return;
            } 

        let params = {
            token: props.auth.token,
            name: categorieTypesName,
            selectedCategorieId: selectedCategoriesTypes ? selectedCategoriesTypes.id : "" ,
        }
        
        setLoading(true);
        props.updateCategoriesTypes(params, res => {
            setLoading(false);
          if( res.status){
             toast.success(res.message);
             props.refreshCategories();
             props.closeModal();
          }
          else {
            toast.error(res.message);
          }
            },
            err => {
            setLoading(false);
            toast.error("Something went wrong!");
        })
    
    };

    useEffect(() => {
        //
      }, [])

    return (
        <> 
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Categories Name</label>
                        <input className="form-control" id="inputName" type="CategoriesTypesName" value={categorieTypesName} placeholder="CategoriesTypes"
                        
                            name="CategoriesTypesName" required="" onChange={(e) => {onChangeInput(e, 'CategoriesTypesName')}} />
                    </div>
                    <div className="form-group text-center col-md-12 mt-3 mb-3">
                        <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                            <span>Close</span>
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-primary" disabled={loading} type="button" onClick={() =>{handleUpdate()}}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save Changes</span>
                        </button>
                    </div>
            </form>
         
        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateCategoriesTypes: bindActionCreators(updateCategoriesTypes, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditCategories);
