import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import AddTags from './add';
import EditTags from './edit';
import Swal from 'sweetalert2';
import { deleteTagsTypes, getAllTags } from '../../actions/tags';


const Tags = (props) => {
    document.title = "Tags";
    const [tags, setTags] = useState([]);
    const [selectedTagsTypes, setSelectedTagsTypes] = useState({});
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState("");
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });


    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    //get All Tags
    const getTags = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: 10,
            is_admin: true,

        }
        setLoading(true)
        props.getAllTags(params, (res) => {
            setLoading(false);
            if (res.status) {
                let tags = res.data.tags;
                setTags(tags);
                setTotalPages(res.data.total_pages)
            }
            else {
                toast.error(res.message);
                setTags([])
                setTotalPages("")
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    // Edit selected TagsTypes
    const editTagsTypes = (tagsTypesId) => {
        let selectedTagsTypes = tags.find(item => item.id == tagsTypesId);
        setSelectedTagsTypes(selectedTagsTypes);

        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Tags' });
            setKey(Math.random());
        }, 200);
    }

    // Delete selected TagsTypes
    const deleteTagsTypes = (tagsTypesId) => {
        Swal.fire({
            title: 'Are you sure want to remove this tag?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    selectedTagsTypesId: tagsTypesId,
                    token: props.auth.token
                }
                props.deleteTagsTypes(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getTags();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };


    useEffect(() => {
        getTags();
    }, [page])

    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-12">
                                <h1 className="m-0">Tags &nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary float-center add-new-btn"
                                        onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Tag' }); setKey(Math.random()); }}
                                    >
                                        <i className="fas fa-plus"></i> Add Tags</button>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        : (
                                            <>
                                                <table style={{ width: '100%' }} className="table table-striped">
                                                    <thead style={{ fontSize: '15px' }}>
                                                        <tr>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Serial No</th>
                                                            <th style={{ borderTop: '0px' }}>Name</th>

                                                            <th style={{ borderTop: '0px' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: '15px' }}>
                                                        {tags && tags.length > 0 ?
                                                            <>
                                                                {tags && tags.map((tags, index) =>
                                                                    <tr key={index} >
                                                                        <td className='pl-3'>{(page - 1) * 10 + index + 1}</td>
                                                                        <td>{tags.name}</td>

                                                                        <td>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                onClick={() => { editTagsTypes(tags.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                onClick={() => { deleteTagsTypes(tags.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-trash'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <tr>
                                                                    <td colSpan='4' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        key={page}
                                                        //count={totalPages}
                                                        count={totalPages || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddTags closeModal={() => handleOnCloseModal()} refreshTags={() => { getTags() }} />
                    : <EditTags closeModal={() => handleOnCloseModal()} selectedTagsTypes={selectedTagsTypes} refreshTags={() => { getTags() }} />
                }
                // size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />

        </>

    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllTags: bindActionCreators(getAllTags, dispatch),
    deleteTagsTypes: bindActionCreators(deleteTagsTypes, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(Tags);