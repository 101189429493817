
import RestClient from '../../../utilities/RestClient';
import { API_URL, AUTH_API_URL } from '../../config'
import message from '../../constants/messages'

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {
  let responseMessage = defaultErrorMessage;
  let payload = response.payload;  // Data returned by API

  if (payload && Array.isArray(payload) && payload.length > 0) {
    responseMessage = payload[0];
  }
  else if (response.msg) {
    responseMessage = response.msg;
  }
  return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
  let res = {};
  if (result.status === 200 || result.status === 201) {
    res = {
      status: true,
      message: responseMessage,
      statusCode: result.status,
      type: message.success,
      data: result.data.payload
    };
  } else {
    res = {
      status: false,
      message: responseMessage,
      type: message.error,
      statusCode: result.status
    };

  }
  return res;
}

/****** action creator for add new Enterprises ********/
export const addEnterprises = (params, cb) => {
  return dispatch => {
    RestClient.post(`${AUTH_API_URL}/enterprises/`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to add new Enterprises . Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for get All Enterprises ********/
export const getAllEnterprises = (params, cb) => {
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/enterprises/`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


   /****** action creator for update Enterprises ********/
   export const updateEnterprises = (params, cb) => {
    const id = params.selectedEnterPrisesId;
    delete params.selectedEnterPrisesId;
    return dispatch => {
        RestClient.put(`${AUTH_API_URL}/enterprises/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to update Enterprises. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };


   /****** action creator for get Enterprise User ********/
   export const getEnterpriseUser = (params, cb) => {
    const id = params.selectedEnterPrisesId;
    delete params.selectedEnterPrisesId;
    return dispatch => {
        RestClient.get(`${AUTH_API_URL}/user_profiles/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to update Enterprises. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };
  

   /****** action creator for delete Enterprise User ********/
   export const deleteUser = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${AUTH_API_URL}/user_profiles/detach-enterprise/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to delete user. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };
   /****** action creator for update Expire Enterprises ********/
   export const updateExpireEnterprises = (params, cb) => {
    const id = params.selectedEnterPrisesId;
    delete params.selectedEnterPrisesId;
    return dispatch => {
        RestClient.put(`${AUTH_API_URL}/enterprises/change-status/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to update Expire Enterprises. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };



/****** action creator for delete Enterprises ********/
export const deleteEnterprises = (params, cb) => {
  const id = params.enterprisesId;
  delete params.enterprisesId;
  return dispatch => {
    RestClient.delete(`${API_URL}//${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to delete Enterprises. Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for upload user ********/
export const uploadUser = (params, cb) => {
  const id = params.enterpriseId;
  delete params.enterpriseId;
  var formData = new FormData();
  for (let file of params.file) {
    formData.append("file", file);
  }
  return dispatch => {
    RestClient.postFormData(`${AUTH_API_URL}/user_profiles/upload/${id}`, formData)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to upload users . Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


