import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL, AUTH_API_URL } from "../../config";
import { logout } from '../../../redux/actions/auth';

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {
  let responseMessage = defaultErrorMessage;
  let payload = response.payload;  // Data returned by API

  if (payload && Array.isArray(payload) && payload.length > 0) {
    responseMessage = payload[0];
  }
  else if (response.msg) {
    responseMessage = response.msg;
  }
  return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
  let res = {};
  if (result.status === 200 || result.status === 201) {
    res = {
      status: true,
      message: responseMessage,
      statusCode: result.status,
      type: message.success,
      data: result.data.payload
    };
  } else {
    res = {
      status: false,
      message: responseMessage,
      type: message.error,
      statusCode: result.status
    };
  }
  return res;
}

/****** action creator for get All Settings ********/
export const getAllSettings = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/settings`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};


  /****** action creator for update Settings ********/
  export const updateSettings = (params, cb) => {
    const id = params.settingsId;
    delete params.settingsId;
    return dispatch => {
        RestClient.put(`${API_URL}/settings/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to update Settings. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };

