import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getAllLockBox, deleteLockboxes } from '../../actions/lockBoxes';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import AddLockBoxes from './add';
import EditLockBox from './edit';
import Swal from 'sweetalert2';

const LockBoxes = (props) => {
    document.title = "Document Types";
    const [lockBox, setLockBox] = useState([]);
    const [selectedLockBox, setSelectedLockBox] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [totalPages, setTotalPages] = useState("");
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    // get all lock box
    const getLockBox = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: 10
        }
        // if (searchInput && searchInput != '') {
        //     params = { ...params, search: searchInput, page: 1,};
        // }
        setLoading(true)
        props.getAllLockBox(params, (res) => {
            setLoading(false);
            if (res.status) {
                let lockBox = res.data.lock_box_types;
                setLockBox(lockBox);
                setTotalPages(res.data.total_pages)
            }
            else {
                toast.error(res.message);
                setLockBox("")
                setTotalPages("")
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    //set the search input keyword
    // const onChangeSearchInput = (searchValue, isClearSearch = false) => {
    //     setSearchInput(searchValue);
    // }

    // set selected lockbox by id
    const editLockBoxDetails = (lockBoxId) => {
        let lockBoxes = lockBox.find(item => item.id == lockBoxId);
        setSelectedLockBox(lockBoxes);

        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Document Type' });
            setKey(Math.random());
        }, 200);
    }

    // Delete the lock box
    const deleteLockBox = (lockBoxId) => {
        Swal.fire({
            title: 'Are you sure want to remove this document?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    lockBoxIdId: lockBoxId,
                    token: props.auth.token
                }
                props.deleteLockboxes(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getLockBox();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };

    useEffect(() => {
        getLockBox();
    }, [page, searchInput])

    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-12">
                                <h1 className="m-0">Document Types &nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary float-center add-new-btn"
                                        onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Document Type' }); setKey(Math.random()); }}
                                    >
                                        <i className="fas fa-plus"></i> Add Document Types</button>
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-6 text-end" >
                                {/* <form className='inputSearchForm'>
                                    <div className='input-group me-2'>
                                        <input type="text" className    ="form-control rounded" onChange={(e) => onChangeSearchInput(e.target.value)} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                        <button className='inputClose' onClick={() => {onChangeSearchInput(""); getLockBox(true)}} type="reset"> <i className="las la-times"></i> </button>
                                    </div>
                                    <button className='searchBtn' type="button" onClick={() => {return searchInput ? getLockBox() : ''}}>Search</button>
                                
                                </form> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        : (
                                            <>
                                                <table style={{ width: '100%' }} className="table table-striped">
                                                    <thead style={{ fontSize: '15px' }}>
                                                        <tr>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Serial No</th>
                                                            <th style={{ borderTop: '0px' }}>Name</th>
                                                            <th style={{ borderTop: '0px' }}>description</th>
                                                            <th style={{ borderTop: '0px' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: '15px' }}>
                                                        {lockBox.length > 0 ?
                                                            <>
                                                                {lockBox.map((lock, index) =>
                                                                    <tr key={index} >
                                                                        <td className='pl-3'>{(page - 1) * 10 + index + 1}</td>
                                                                        <td>{lock.name.length > 50  ? lock.name.substring(0, 50) + " ..." : lock.name}</td>
                                                                        <td>{lock.description.length > 50 ? lock.description.substring(0, 50) + " ..." : lock.description  }</td>
                                                                        <td>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                onClick={() => { editLockBoxDetails(lock.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                onClick={() => { deleteLockBox(lock.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-trash'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <tr>
                                                                    <td colSpan='4' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        key={page}
                                                        //count={totalPages}
                                                        count={totalPages || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddLockBoxes closeModal={() => handleOnCloseModal()} refreshLockBox={() => { getLockBox() }} />
                    : <EditLockBox closeModal={() => handleOnCloseModal()} selectedLockBox={selectedLockBox} refreshLockBox={() => { getLockBox() }} />

                }
                // size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />

        </>

    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllLockBox: bindActionCreators(getAllLockBox, dispatch),
    deleteLockboxes: bindActionCreators(deleteLockboxes, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(LockBoxes);