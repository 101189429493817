
import RestClient from '../../../utilities/RestClient';
import {API_URL} from '../../config'
import message from '../../constants/messages'

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {
    let responseMessage = defaultErrorMessage;
    let payload = response.payload;  // Data returned by API
    if (payload && Array.isArray(payload) && payload.length > 0) {
        responseMessage = payload[0];
    }
    else if (response.msg) {
        responseMessage = response.msg;
    }
    return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
    let res = {};
    if (result.status === 200 || result.status === 201){
      res = {
        status: true,
        message: responseMessage,
        statusCode: result.status,
        type: message.success,
        data: result.data.payload
      };
    } else {
      res = {
        status: false,
        message: responseMessage,
        type: message.error,
        statusCode: result.status
      };
    }
    return res;
}

/****** action creator for get All roles ********/
export const getAllCareRoles = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/care_roles`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for add new care Role ********/
export const addCareRole = (params, cb) => {
 return dispatch => {
     RestClient.post(`${API_URL}/care_roles`, params)
     .then(result => {
       let response = result.data;    // Response returned by server
       let responseMessage = getApiResponseMessage(response, 'Unable to add care Role. Please try again later.');   
       let res = getApiResponse(result, responseMessage);
       cb(res);
     })
     .catch(error => {
       let res = {
         status: false,
         message: message.commonError,
         type: message.error
       };
       cb(res);
     });
 };
};

/****** action creator for delete care Role ********/
export const deleteCareRole = (params, cb) => {
    const id = params.careRoleIdId;
       delete params.careRoleIdId;
    return dispatch => {
        RestClient.delete(`${API_URL}/care_roles/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to delete care Role. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };

  /****** action creator for update care Role ********/
export const updateCareRole = (params, cb) => {
    const id = params.selectedCareRoleId;
    delete params.selectedCareRoleId;
    return dispatch => {
        RestClient.put(`${API_URL}/care_roles/${id}`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response, 'Unable to update care Role. Please try again later.');   
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };
