import React, { useState,useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { updateCareRole } from '../../actions/careRole';

const EditCareRole = (props) => {
    const selectedCareRole = props.selectedCareRole;
    const [careRoleName, setCareRoleName] = useState(selectedCareRole ? selectedCareRole.name : "");
    const [description, setDescription] = useState(selectedCareRole ? selectedCareRole.description : "");
    const [loading, setLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "CareRoleName") {
            setCareRoleName(value.trimStart());
        }
        else if (fieldName === "description") {
            setDescription(value.trimStart());
        }
      };
    
    //edit care role
    const handleUpdate = () => {
        if (!careRoleName) {
            toast.error("Please enter careRole name");
            return;
            } 
            else if (!description) {
            toast.error("Please enter description");
            return;
        }

        let params = {
            token: props.auth.token,
            name: careRoleName,
            description : description,
            selectedCareRoleId: selectedCareRole ? selectedCareRole.id : "" ,
        }
        
        setLoading(true);
        props.updateCareRole(params, res => {
            setLoading(false);
          if( res.status){
             toast.success(res.message);
             props.refreshCareRole();
             props.closeModal();
          }
          else {
            toast.error(res.message);
          }
            },
            err => {
            setLoading(false);
            toast.error("Something went wrong!");
        })
    
    };

    useEffect(() => {
        //
      }, [])

    return (
        <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Care Role Name</label>
                    <input className="form-control" id="inputName" type="text" value={careRoleName} placeholder="Care Role Name"
                    
                        name="CareRole Name" required="" onChange={(e) => {onChangeInput(e, 'CareRoleName')}} />
                </div>
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Description</label>
                    <input className="form-control" id="inputName" type="text" value={description} placeholder="Description"
                    
                        name="description" required="" onChange={(e) => {onChangeInput(e, 'description')}} />
                </div>
                <div className="form-group text-center col-md-12 mt-3 mb-3">
                    <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                        <span>Close</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={loading} type="button" onClick={() =>{handleUpdate()}}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Save Changes</span>
                    </button>
                </div>
        </form>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateCareRole: bindActionCreators(updateCareRole, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditCareRole);
