import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { addNewCategories } from "../../actions/categories";

const AddCategories = (props) => {
    const [categoriesName, setCategoriesName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "CategoriesName") {
            setCategoriesName(value.trimStart());
        }
    };

    //add new Categories
    const handleSubmit = () => {
        if (!categoriesName) {
            toast.error("Please enter category name");
            return;
        }

        let params = {
            token: props.auth.token,
            name: categoriesName,
        }

        setIsLoading(true);
        props.addNewCategories(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                 props.refreshCategories();
                 props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
        //
    }, [])


    return (
        <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
            <div className="form-group mt-3 mb-3 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Categories Name</label>
                <input className="form-control" id="inputName" type="text" value={categoriesName} placeholder="Categories Name"
                    name="Categories Name" required="" onChange={(e) => { onChangeInput(e, 'CategoriesName') }} />
            </div>
            <div className="form-group text-center col-md-12 mt-3 mb-3">
                <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                    <span>Close</span>
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() =>{handleSubmit()}}>
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Submit</span>
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    addNewCategories: bindActionCreators(addNewCategories, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCategories);