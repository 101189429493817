import React, { useState,useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { updateMedicalConditions } from '../../actions/medicalConditions';
import Button from 'react-bootstrap/Button';


const EditMedicalConditions = (props) => {
    const selectedMedicalConditions = props.selectedMedicalConditions;
    const [medicationName, setMedicationName] = useState(selectedMedicalConditions ? selectedMedicalConditions.name : "");
    const [description, setDescription] = useState(selectedMedicalConditions ? selectedMedicalConditions.description : "");
    const [loading, setLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "medicationName") {
            setMedicationName(value.trimStart());
        }
        else if (fieldName === "description") {
            setDescription(value.trimStart());
        }
      };
    
    //edit medical conditions
    const handleUpdate = () => {
        if (!medicationName) {
            toast.error("Please enter medication name");
            return;
            } 
            else if (!description) {
            toast.error("Please enter description");
            return;
        }

        let params = {
            token: props.auth.token,
            name: medicationName,
            description : description,
            selectedMedicalId: selectedMedicalConditions ? selectedMedicalConditions.id : "" ,
        }
        
        setLoading(true);
        props.updateMedicalConditions(params, res => {
            setLoading(false);
          if( res.status){
             toast.success(res.message);
             props.refreshMedicalConditions();
             props.closeModal();
          }
          else {
            toast.error(res.message);
          }
            },
            err => {
            setLoading(false);
            toast.error("Something went wrong!");
        })
    
    };

    useEffect(() => {
        //
      }, [])

    return (
        <> 
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Medication Name</label>
                        <input className="form-control" id="inputName" type="medicationName" value={medicationName} placeholder="Medication Name"
                        
                            name="medicationName" required="" onChange={(e) => {onChangeInput(e, 'medicationName')}} />
                    </div>
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Description</label>
                        <input className="form-control" id="inputName" type="description" value={description} placeholder="Description"
                        
                            name="description" required="" onChange={(e) => {onChangeInput(e, 'description')}} />
                    </div>
                    <div className="form-group text-center col-md-12 mt-3 mb-3">
                        <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                            <span>Close</span>
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-primary" disabled={loading} type="button" onClick={() =>{handleUpdate()}}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save Changes</span>
                        </button>
                    </div>
            </form>
         
        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateMedicalConditions: bindActionCreators(updateMedicalConditions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditMedicalConditions);
