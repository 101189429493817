import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import moment from 'moment';


const View = (props) => {

    const [loading, setLoading] = useState(false);
    const [enterprisesDetail, setEnterprisesDetail] = useState({});
 

    useEffect(() => {
       
    }, [])

    return (
        <>
            <div className="row ps-5 pe-5 pt-3 pb-4 m-0">
                {loading ?
                    <div className="col-12 text-center p-5 mt-5 mb-5">
                        <div className="spinner-grow text-success" role="status"></div>
                    </div>
                :
                    <>
                        <div className="col-lg-6">
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Enterprises name:</label>
                                {/* <span>{ userDetail && userDetail.user_profiles && userDetail.user_profiles.firstname ? userDetail.user_profiles.firstname : "N/A"} </span> */}
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Number of enterprises user:</label>
                                {/* <span>{userDetail && userDetail.user_profiles && userDetail.user_profiles.lastname ? userDetail.user_profiles.lastname : "N/A"}</span> */}
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Contact No:</label>
                                {/* <span>{userDetail && userDetail.user_profiles && userDetail.user_profiles.dob ? moment(userDetail.user_profiles.dob).format("YYYY/MM/DD") : "N/A"}</span> */}
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Address:</label>
                                {/* <span>{userDetail && userDetail.user_profiles && userDetail.user_profiles.phone_code ? userDetail.user_profiles.phone_code.includes('+') ?  userDetail.user_profiles.phone_code + "-" + userDetail.user_profiles.phone_no : '+' + userDetail.user_profiles.phone_code + "-" + userDetail.user_profiles.phone_no : "N/A"}</span> */}
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">valid till:</label>
                                {/* <span>{userDetail && userDetail.user_profiles && userDetail.user_profiles.is_email_verified &&  userDetail.user_profiles.is_email_verified ===true ? "Yes" : "No"}</span> */}
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Subscription code:</label>
                                {/* <span>{userDetail && userDetail.is_active === true  ? "Active" : "inActive"}</span> */}
                            </div><br />
                        </div>

                        <div className="col-lg-6">
                            {/* <div>
                                <label className="label mr-2" htmlFor="lfname">Number of enterprises user :</label>
                                <span>{userDetail && userDetail.email ? userDetail.email : "N/A"}</span>
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Created At:</label>
                                <span> {userDetail && userDetail.created_at ? moment(userDetail.created_at).format("YYYY/MM/DD") : "N/A"}</span>
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Updated At:</label>
                                <span> {userDetail && userDetail.user_profiles && userDetail.user_profiles.updated_at ? moment(userDetail.user_profiles.updated_at).format("YYYY/MM/DD") : "N/A"}</span>
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Adress:</label>
                                <span>{userDetail && userDetail.user_profiles && userDetail.user_profiles.address ? userDetail.user_profiles.address : "N/A"}</span>
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">ID:</label>
                                <span>{userDetail && userDetail.id ? userDetail.id : "N/A"}</span>
                            </div><br />
                            <div>
                                <label className="label mr-2" htmlFor="lfname">Biometric</label>
                                <span>{userDetail && userDetail.user_profiles && userDetail.user_profiles.is_biometric && userDetail.user_profiles.is_biometric === true ? "Yes" : "No"}</span>
                            </div><br />
                       */}
                            
                        </div>
                    </>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    //
});

export default connect(mapStateToProps, mapDispatchToProps)(View);