import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import AddMedlist from './add';
import EditMedlist from './edit';
import Swal from 'sweetalert2';
import { deleteMedlist, getAllMedlist } from '../../actions/medlist';


var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();


const Medlist = (props) => {
    document.title = "MedList";
    const [medlist, setMedlist] = useState([]);
    const [selectedMedlist, setSelectedMedlist] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState("");
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }


    //get All Medlists
    const getMedlist = (clearSearch = false) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: 10,
            is_admin: true,
        }

        if (!clearSearch && searchInput && searchInput != '') {
            params = { ...params, search: searchInput.trim(), page: 1, };
        }

        setLoading(true)
        props.getAllMedlist(params, (res) => {
            setLoading(false);
            if (res.statusCode == 200 || res.statusCode == 201) {
                let medlist = res.data.medlists;
                setMedlist(medlist);
                setTotalPages(res.data.total_pages)
            }
            else {
                setMedlist([])
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }

        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }


    //get id of selected medlists
    const editMedlistDetails = (medlistId) => {
        let selectedMedlist = medlist.find(item => item.id == medlistId);
        setSelectedMedlist(selectedMedlist);

        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Medlist' });
            setKey(Math.random());
        }, 200);
    }

    // Delete selected medlists
    const deleteMedlist = (medlistId) => {
        Swal.fire({
            title: 'Are you sure want to remove this Medlist?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    id: medlistId,
                    token: props.auth.token
                }
                props.deleteMedlist(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getMedlist();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };

    const removeExtraSpace = (s) => s.replace(/(&nbsp;)*/g, '');

    //search input
    const onChangeSearchInput = (searchValue) => {
        setSearchInput(searchValue);
    }


    useEffect(() => {
        getMedlist();
    }, [page, searchInput])

    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-6">
                                <h1 className="m-0">Medlist &nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Medlist' }); setKey(Math.random()); }}
                                    >
                                        <i className="fas fa-plus"></i> Add Medlist</button>
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-6 text-end">
                                <form className='inputSearchForm'>
                                    <div className='input-group me-2'>
                                        <input type="text" className="form-control rounded" onChange={(e) => onChangeSearchInput(e.target.value)} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                        {/* <button className='inputClose' onClick={() => { onChangeSearchInput(""); getMedlist(true) }} type="reset"> <i className="las la-times"></i> </button> */}
                                    </div>
                                    <button className='searchBtn' type="button" onClick={() => { return searchInput ? getMedlist() : '' }}>Search</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        : (
                                            <>
                                                <table style={{ width: '100%' }} className="table table-striped">
                                                    <thead style={{ fontSize: '15px' }}>
                                                        <tr>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Serial No</th>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Name</th>
                                                            <th style={{ borderTop: '0px' }}>Content</th>
                                                            <th style={{ borderTop: '0px' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: '15px' }}>
                                                        {medlist && medlist.length > 0 ?
                                                            <>
                                                                {medlist && medlist.map((medlist, index) =>
                                                                    <tr key={index} >
                                                                        <td className='pl-3'>{(page - 1) * 10 + index + 1}</td>
                                                                        <td> {(medlist.name.length > 50 ? medlist.name.substring(0, 50) + " ..." : medlist.name)}</td>
                                                                        <td>
                                                                            {(medlist && medlist.description ?
                                                                                medlist.description.length > 50
                                                                                    ? htmlToReactParser.parse(removeExtraSpace(medlist.description.substring(0, 50) + "..."))
                                                                                    : htmlToReactParser.parse(removeExtraSpace(medlist.description))
                                                                                : "N/A"
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                onClick={() => { editMedlistDetails(medlist.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                onClick={() => { deleteMedlist(medlist.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-trash'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <tr>
                                                                    <td colSpan='4' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        key={page}
                                                        //count={totalPages}
                                                        count={totalPages || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddMedlist closeModal={() => handleOnCloseModal()} refreshMedlist={() => { getMedlist() }} />
                    : <EditMedlist closeModal={() => handleOnCloseModal()} selectedMedlist={selectedMedlist} refreshMedlist={() => { getMedlist() }} />
                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />

        </>

    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllMedlist: bindActionCreators(getAllMedlist, dispatch),
    deleteMedlist: bindActionCreators(deleteMedlist, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(Medlist);