import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getAllMedicalConditions, deleteMedicalCondition } from '../../actions/medicalConditions';
import moment from 'moment'
import EditMedicalConditions from './edit';
import AddMedicalConditions from './add';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import Swal from 'sweetalert2';

const MedicalConditions = (props) => {
    document.title = "Medical Conditions";
    const [medicalConditions, setMedicalConditions] = useState([]);
    const [selectedMedicalConditions, setSelectedMedicalConditions] = useState({});
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState("");
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    // Close modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    // Get all medicalConditions
    const getMedicalConditions = (clearSearch = false) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: 10
        }

        setLoading(true);
        props.getAllMedicalConditions(params, res => {
            setLoading(false);
            if (res.status) {
                let conditions = res.data.conditions;
                setMedicalConditions(conditions);
                setTotalPages(res.data.total_pages);
            }
            else {
                if (res.statusCode === 404) {
                    setMedicalConditions([]);
                    setTotalPages(0);
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    //get id of selected medicalConditions
    const editMedicalConditionsDetails = (medicalConditionId) => {
        let medicalCondition = medicalConditions.find(item => item.id == medicalConditionId);
        setSelectedMedicalConditions(medicalCondition);

        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Medical Conditions' });
            setKey(Math.random());
        }, 200);
    }

    // Delete selected medicalConditions
    const deleteMedicalCondition = (medicalConditionId) => {
        Swal.fire({
            title: 'Are you sure want to remove this medicalCondition?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    selectedMedicalId: medicalConditionId,
                    token: props.auth.token
                }
                props.deleteMedicalCondition(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getMedicalConditions();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };

    useEffect(() => {
        getMedicalConditions();
    }, [page])

    return (
        <>
            <div>
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12 col-md-12">
                                    <h1 className="m-0">Medical Conditions &nbsp;&nbsp;
                                        <button type="button" className="btn btn-primary float-center add-new-btn" onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Medical Conditions' }); setKey(Math.random()); }}>
                                            <i className="fas fa-plus"></i> Add Medical Conditions</button>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            : (
                                                <>
                                                    <table style={{ width: '100%' }} className="table table-striped">
                                                        <thead style={{ fontSize: '15px' }}>
                                                            <tr>
                                                                <th style={{ borderTop: '0px' }} className='pl-3'>Serial No</th>
                                                                <th style={{ borderTop: '0px' }}>Name</th>
                                                                <th style={{ borderTop: '0px' }}>Description</th>
                                                                <th style={{ borderTop: '0px' }}>Created At</th>
                                                                <th style={{ borderTop: '0px' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ fontSize: '15px' }}>
                                                            {medicalConditions && medicalConditions.length > 0 ?
                                                                <>
                                                                    {medicalConditions.map((Medication, index) =>
                                                                        <tr key={index} >
                                                                            <td className='pl-3'>{(page - 1) * 10 + index + 1}</td>
                                                                            <td>{Medication.name ? Medication.name.length > 50 ? Medication.name.substring(0, 50) + " ..." : Medication.name : "--"}</td>
                                                                            <td>{Medication.description ? Medication.description.length > 50 ? Medication.description.substring(0, 50) + " ..." : Medication.description : "--"}</td>
                                                                            <td>{moment(Medication.created_at ? Medication.created_at : "--").format("YYYY/MM/DD")}</td>
                                                                            <td>
                                                                                {/* <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => {viewMedicalConditionsDetails(Medication.id)}} 
                                                                                    //onClick={() => {editMedicalConditionsDetails(Medication.id)}}
                                                                                    style={{ background: '#17a2b8', color: 'white' }}> 
                                                                                <span>
                                                                                    <i className='fas fa-eye'></i>&nbsp;
                                                                                </span>
                                                                            </button> */}
                                                                                <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { editMedicalConditionsDetails(Medication.id) }}
                                                                                    style={{ background: '#17a2b8', color: 'white' }}>
                                                                                    <span>
                                                                                        <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                                    </span>
                                                                                </button>
                                                                                <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { deleteMedicalCondition(Medication.id) }}
                                                                                    style={{ background: '#17a2b8', color: 'white' }}>
                                                                                    <span>
                                                                                        <i className='fas fa-trash'></i>&nbsp;
                                                                                    </span>
                                                                                </button>
                                                                            </td>
                                                                        </tr>

                                                                    )}
                                                                </>
                                                                :
                                                                <>
                                                                    <tr>
                                                                        <td colSpan='7' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                    </tr>
                                                                </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <Box py={3} display="flex" justifyContent="center">
                                                        <Pagination
                                                            key={page}
                                                            //count={totalPages}
                                                            count={totalPages || 0}
                                                            color="secondary"
                                                            variant="outlined"
                                                            onChange={(e, value) => setPage(value)}
                                                            defaultPage={page}
                                                        />
                                                    </Box>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'edit'
                    ? <EditMedicalConditions closeModal={() => handleOnCloseModal()} selectedMedicalConditions={selectedMedicalConditions} refreshMedicalConditions={() => { getMedicalConditions() }} />
                    : <AddMedicalConditions closeModal={() => handleOnCloseModal()} refreshMedicalConditions={() => { getMedicalConditions() }} />
                }
                // size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllMedicalConditions: bindActionCreators(getAllMedicalConditions, dispatch),
    deleteMedicalCondition: bindActionCreators(deleteMedicalCondition, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MedicalConditions);