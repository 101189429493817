import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { addLockBox } from '../../actions/lockBoxes';
import Button from 'react-bootstrap/Button';

const AddLockBoxes = (props) => {
    const [lockBoxName, setLockBoxName] = useState("");
    const [description, setDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "lockBoxName") {
            setLockBoxName(value.trimStart());
        }
        else if (fieldName === "description") {
            setDescription(value.trimStart());
        }
    };

    //add new lock Box
    const handleSubmit = () => {
        if (!lockBoxName) {
            toast.error("Please enter name");
            return;
        }
        else if (!description) {
            toast.error("Please enter description");
            return;
        }

        let params = {
            token: props.auth.token,
            name: lockBoxName,
            description: description,
        }
        
        setIsLoading(true);
        props.addLockBox(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshLockBox();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
        //
    }, [])


    return (
        <>
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName"> Name</label>
                    <input className="form-control" id="inputName" type="text" value={lockBoxName} placeholder="name"
                        name="lockBox Name" required="" onChange={(e) => { onChangeInput(e, 'lockBoxName') }} />
                </div>
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Description</label>
                    <input className="form-control" id="inputName" type="text" value={description} placeholder="Description"

                        name="description" required="" onChange={(e) => { onChangeInput(e, 'description') }} />
                </div>
                <div className="form-group text-center col-md-12 mt-3 mb-3">
                    <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                        <span>Close</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() =>{handleSubmit()}}>
                        {isLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Submit</span>
                    </button>
                </div>
            </form>

        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
   addLockBox: bindActionCreators(addLockBox, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddLockBoxes);