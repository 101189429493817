import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth'

const Footer = (props) => {
    const history = useHistory();
    const userLogout = () => {
        props.logout();
        history.push('/login');
    }

    return (
        <div>
            <footer className="main-footer">
                {/* <strong>Copyright © 2020 <a href="/">Sunless, Inc</a>. </strong>
                All rights reserved. | TOS | Privacy | Cookies */}
                <strong>©2022 <a onClick={() => {history.push('/')}}>Shepherd Company LLC.</a> </strong>
                All rights reserved.
                <div className="float-right">
                    {/* <a onClick={() => {userLogout()}}><b className='logout'>Log Out</b></a> */}
                </div>
            </footer>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    logout: () =>  dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);