import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import CalendarImage from "../../public/images/calender.svg";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addEnterprises } from "../../actions/enterprises";

const AddEnterprises = (props) => {
    const [enterprisesName, setEnterprisesName] = useState("")
    const [enterprisesUser, setEnterprisesUser] = useState("");
    const [enterprisesDate, setEnterprisesDate] = useState(null);
    const [code, setCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "enterprisesName") {
            setEnterprisesName(value);
        }
        else if (fieldName === "enterprisesUser") {
            setEnterprisesUser(value.replace(/\D/g, ''));
        }
        else if (fieldName === "code") {
            setCode(value.trim());
        }
    };

    //add new enterprises
    const handleSubmit = () => {
        if (!enterprisesName) {
            toast.error("Please enter enterprises name");
            return;
        }
        else if (!enterprisesUser) {
            toast.error("Please enter  users count");
            return;
        }
        else if (enterprisesUser.length < 1) {
            toast.error("count should be maximum 1 character")
            return;
        }
        else if (!enterprisesDate) {
            toast.error("Please select the Subscription plan valid till date ");
            return;
        }
        else if (!code) {
            toast.error("please enter code");
            return;
        }
        else if (code.length <= 2 || code.length > 50) {
            toast.error("Subscription code should have min 3 character and max 50 character")
            return;
        }
        let params = {
            token: props.auth.token,
            name: enterprisesName,
            allow_users_count: enterprisesUser,
            expiry_date: enterprisesDate,
            subscription_code: code,
        }
        setIsLoading(true);
        props.addEnterprises(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshEnterprises();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })
    };

    useEffect(() => {
        //
    }, [])


    return (
        <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
            <div className="form-group mt-3 mb-3 col-md-12">
                <label className="label_text" htmlFor="inputFirstName"> Enterprises name</label>
                <input className="form-control" id="inputName" type="text" value={enterprisesName} placeholder="Enterprises name"

                    name="enterprisesName" required="" onChange={(e) => { onChangeInput(e, 'enterprisesName') }} />
            </div>
            <div className="form-group mt-3 mb-3 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Allow users count</label>
                <input className="form-control" id="inputName" type="number" value={enterprisesUser} placeholder="Number of enterprises user"

                    name="enterprisesUser" required="" onChange={(e) => { onChangeInput(e, 'enterprisesUser') }} />
            </div>
            <div className="form-group mt-3 mb-2 col-md-12">
                <label className="label_text" htmlFor="">Subscription plan valid till</label>
                <label className="form-control" >
                    <DatePicker
                        selected={enterprisesDate}
                        onChange={(date) => setEnterprisesDate(date)}
                        dateFormat="MM/dd/yyyy"
                        minDate={moment().toDate()}
                        placeholderText="MM/DD/YYYY"
                    />
                    <img src={CalendarImage} className="input_icon_date img-fluid" alt=" " />
                </label>
            </div>

            <div className="form-group mt-3 mb-3 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Subscription code</label>
                <input className="form-control" id="inputName" type="text" value={code} placeholder=" Subscription code"

                    name="subscription code" required="" onChange={(e) => { onChangeInput(e, 'code') }} />
                <span className="subscription" >
                    (Subscription code should have min 3 character and max 50 character) 
                </span>
            </div>
            <div className="form-group text-center col-md-12 mt-3 mb-3">
                <button className="btn btn-secondary" type="button" onClick={() => { props.closeModal() }}>
                    <span>Close</span>
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleSubmit() }}>
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Submit</span>
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    addEnterprises: bindActionCreators(addEnterprises, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEnterprises);