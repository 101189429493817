import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getAllCareRoles, deleteCareRole } from '../../actions/careRole';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import AddCareRole from './add';
import EditCareRole from './edit';
import Swal from 'sweetalert2';
import PaginationSetting from '../../constants/pagination';

const CareRole = (props) => {
    document.title = "Care Role";
    const [pagination, setPagination] = useState(PaginationSetting)
    const [careRoles, setCareRoles] = useState([]);
    const [selectedCareRole, setSelectedCareRole] = useState({});
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    // get all care role
    const getCareRoles = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }
        setLoading(true)
        props.getAllCareRoles(params, (res) => {
            setLoading(false);
            if (res.status) {
                let careRoles = res.data.careroles;
                setCareRoles(careRoles);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.total_pages, totalRecords: res.data.total }));
            }
            else {
                setCareRoles([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    // get selected care role id
    const editCareRoleDetails = (careRoleId) => {
        let careRole = careRoles.find(item => item.id == careRoleId);
        setSelectedCareRole(careRole);

        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Care Role' });
            setKey(Math.random());
        }, 200);
    }

    // delete care role
    const deleteCareRole = (careRoleId) => {
        Swal.fire({
            title: 'Are you sure want to remove this careRole?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    careRoleIdId: careRoleId,
                    token: props.auth.token
                }
                props.deleteCareRole(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getCareRoles();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };

    useEffect(() => {
        getCareRoles();
    }, [page])

    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-12">
                                <h1 className="m-0">Care Role &nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary float-center add-new-btn"
                                        onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Care Role' }); setKey(Math.random()); }}
                                    >
                                        <i className="fas fa-plus"></i> Add Care Role</button>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content pb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <table style={{ width: '100%' }} className="table table-striped">
                                                <thead style={{ fontSize: '15px' }}>
                                                    <tr>
                                                        <th style={{ borderTop: '0px' }} className='text-center'>Sr. No.</th>
                                                        <th style={{ borderTop: '0px' }}>Name</th>
                                                        <th style={{ borderTop: '0px' }}>Description</th>
                                                        <th style={{ borderTop: '0px' }}>Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody style={{ fontSize: '15px' }}>
                                                    {careRoles && careRoles.length > 0 ?
                                                        <>
                                                            {careRoles.map((role, index) =>
                                                                <tr key={index} >
                                                                    <td className='text-center'>{(page - 1) * pagination.recordsPerPage + index + 1}</td>
                                                                    <td>{role.name.length > 50 ? role.name.substring(0,50) + " ..." : role.name }</td>
                                                                    <td>{role.description.length> 50 ? role.description.substring(0,50) + " ..." : role.description}</td>
                                                                    <td>
                                                                        <button className='btn btn-sm btn-del-key internal mr-2'
                                                                            onClick={() => { editCareRoleDetails(role.id) }}
                                                                            style={{ background: '#17a2b8', color: 'white' }}>
                                                                            <span>
                                                                                <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                            </span>
                                                                        </button>
                                                                        <button className='btn btn-sm btn-del-key internal mr-2'
                                                                            onClick={() => { deleteCareRole(role.id) }}
                                                                            style={{ background: '#17a2b8', color: 'white' }}>
                                                                            <span>
                                                                                <i className='fas fa-trash'></i>&nbsp;
                                                                            </span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan='4' style={{ textAlign: 'center' }}>No Data Found</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>

                                            {pagination.totalRecords > pagination.recordsPerPage &&
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        key={page}
                                                        count={pagination.totalPages || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddCareRole closeModal={() => handleOnCloseModal()} refreshCareRole={() => { getCareRoles() }} />
                    : <EditCareRole closeModal={() => handleOnCloseModal()} selectedCareRole={selectedCareRole} refreshCareRole={() => { getCareRoles() }} />
                }
                // size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>

    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllCareRoles: bindActionCreators(getAllCareRoles, dispatch),
    deleteCareRole: bindActionCreators(deleteCareRole, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(CareRole);