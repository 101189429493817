import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import CalendarImage from "../../public/images/calender.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { deleteUser, getEnterpriseUser, updateEnterprises, uploadUser } from "../../actions/enterprises";

const Edit = (props) => {
    const selectedEnterprises = props.selectedEnterprises;
    const [enterprisesName, setEnterprisesName] = useState(selectedEnterprises ? selectedEnterprises.name : "")
    const [enterprisesUser, setEnterprisesUser] = useState(selectedEnterprises ? selectedEnterprises.allow_users_count : "");
    const [enterprisesDate, setEnterprisesDate] = useState(selectedEnterprises.expiry_date);
    const [code, setCode] = useState(selectedEnterprises ? selectedEnterprises.subscription_code : "");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [enterpriseUserDetail, setEnterpriseUserDetail] = useState([]);
    // console.log(enterpriseUserDetail, "enterpriseUserDetail");

    const baseUrl = window.location.protocol + '//' + window.location.host;

    const maskGenerator = createDefaultMaskGenerator('+1-999-999-9999');

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "enterprisesName") {
            setEnterprisesName(value);
        }
        else if (fieldName === "enterprisesUser") {
            setEnterprisesUser(value.replace(/\D/g, ''));
        }
        else if (fieldName === "code") {
            setCode(value.trim());
        }
    };

    //edit enterprise
    const handleUpdate = () => {
        if (!enterprisesName) {
            toast.error("Please enter enterprises name");
            return;
        }
        else if (!enterprisesUser) {
            toast.error("Please enter number of enterprises user");
            return;
        }

        else if (!enterprisesDate) {
            toast.error("Please select the Subscription plan valid till date ");
            return;
        }
        else if (!code) {
            toast.error("Please enter subscription code");
            return;
        }
        else if (code.length <= 2 || code.length > 50) {
            toast.error("Subscription code should have min 3 character and max 50 character")
            return;
        }

        let params = {
            token: props.auth.token,
            name: enterprisesName,
            expiry_date: enterprisesDate,
            subscription_code: code,
            allow_users_count: enterprisesUser,
            selectedEnterPrisesId: selectedEnterprises ? selectedEnterprises.id : "",
        }

        setLoading(true);
        props.updateEnterprises(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshEnterprises();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })

    };

    //upload users excel file
    const onSelectFiles = (e) => {
        const files = e.target.files;
        if (files.length <= 0) {
            return;
        }
        let filesArr = [];
        for (let file of files) {
            filesArr.push(file);
        }
        let params = {
            token: props.auth.token,
            enterpriseId: selectedEnterprises ? selectedEnterprises.id : "",
            file: filesArr ? filesArr : ""
        }
        setLoading(true);
        props.uploadUser(params, (res) => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message)
                getEnterpriseUser();
            }
            else {
                toast.error(res.message);
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    // get enterprise user
    const getEnterpriseUser = () => {
        let params = {
            token: props.auth.token,
            selectedEnterPrisesId: selectedEnterprises ? selectedEnterprises.id : "",
        }
        setLoading(true);
        props.getEnterpriseUser(params, (res) => {
            setLoading(false);
            if (res.status) {
                let enterprises = res.data
                setEnterpriseUserDetail(enterprises);
            }
            else {
                toast.error(res.message);
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    // delete enterprise user
    const deleteEnterpriseUser = (enterprisesId) => {
        Swal.fire({
            title: 'Are you sure want to remove this user?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {

            if (result.isConfirmed) {
                let params = {
                    id: enterprisesId,
                    token: props.auth.token
                }

                props.deleteUser(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getEnterpriseUser();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {

            }
        })
    };

    useEffect(() => {
        getEnterpriseUser();
    }, [])

    return (
        <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >

            <nav className="tbs">
                <ul ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="nav-home-tab" data-bs-toggle="pill" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Basic Detail</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="nav-profile-tab" data-bs-toggle="pill" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Users Detail</a>
                    </li>
                </ul>
            </nav>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName"> Enterprises name</label>
                        <input className="form-control" id="inputName" type="text" value={enterprisesName} placeholder="Enterprises name"
                            name="enterprisesName" required="" onChange={(e) => { onChangeInput(e, 'enterprisesName') }} />
                    </div>
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Number of enterprises user</label>
                        <input className="form-control" id="inputName" type="text" value={enterprisesUser} placeholder="Number of enterprises user"
                            name="enterprisesUser" required="" onChange={(e) => { onChangeInput(e, 'enterprisesUser') }} />
                    </div>
                    <div className="form-group mt-3 mb-2 col-md-12">
                        <label className="label_text" htmlFor="">Subscription plan valid till</label>
                        <label className="form-control" >
                            <DatePicker
                                selected={new Date(enterprisesDate)}
                                onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy"
                                // minDate={moment().toDate()}
                                minDate={new Date(enterprisesDate)}
                                placeholderText="MM/DD/YYYY"
                            />
                            <img src={CalendarImage} className="input_icon_date img-fluid" alt=" " />
                        </label>
                    </div>

                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Subscription code</label>
                        <input className="form-control" id="inputName" type="text" value={code} placeholder=" Subscription code"
                            name="subscription code" required="" onChange={(e) => { onChangeInput(e, 'code') }} />
                        <span className="subscription" >
                            (Subscription code should have min 3 character and max 50 character)
                        </span>
                    </div>

                    <div className="form-group text-center col-md-12 mt-3 mb-3">
                        <button className="btn btn-secondary" type="button" onClick={() => { props.closeModal() }}>
                            <span>Close</span>
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-primary" disabled={loading} type="button" onClick={() => { handleUpdate() }}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <section className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            : (
                                                <>
                                                    <div className='user_details'>
                                                        <div className="uploadenterprise text-end">
                                                            <button type="button" className="uploadBtn">
                                                                <input type="file" id="file" className="form-control inputfile d-none"
                                                                    onChange={(e) => { onSelectFiles(e) }}
                                                                    multiple accept=".xlsx, .xls"
                                                                />
                                                                <label className="chooseFiles" htmlFor="file" style={{ cursor: "pointer" }}>
                                                                    <span className="teamUser_name">Upload Users</span>
                                                                </label>
                                                            </button><br />
                                                            <a className="sampleClass" onClick={() => { window.open(baseUrl + '/download/enterprise_users_sample.xlsx') }}>Download Sample</a>
                                                        </div>
                                                    </div>
                                                    <table style={{ width: '100%', marginTop: "0px" }} className="table table-striped">
                                                        <thead style={{ fontSize: '15px' }}>
                                                            <tr>
                                                                <th className='user_details'>S. No</th>
                                                                <th className='user_details'>Name</th>
                                                                <th className='user_details'>Email</th>
                                                                <th className='user_details'>Phone No</th>
                                                                <th className='user_details'>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ fontSize: '15px' }}>
                                                            {enterpriseUserDetail.length > 0 ?
                                                                <>
                                                                    {enterpriseUserDetail.map((enterprises, index) =>
                                                                        <tr key={index} >
                                                                            <td className='pl-3'>{(page - 1) * 10 + index + 1}</td>
                                                                            <td>{enterprises.firstname + enterprises.lastname}</td>
                                                                            <td>{enterprises.user.email}</td>
                                                                            <td>
                                                                                <MaskedInput
                                                                                    className="maskedinput"
                                                                                    maskGenerator={maskGenerator}
                                                                                    value={enterprises.phone_code.replace("+", "") + enterprises.phone_no}
                                                                                    disabled
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <a className='btn btn-sm internal mr-2'
                                                                                    onClick={() => { deleteEnterpriseUser(enterprises.id) }}
                                                                                    style={{ background: '#ff0000bf', color: 'white' }}>
                                                                                    <span>
                                                                                        Delete
                                                                                    </span>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <tr >
                                                                        <td colspan="5" className="pt-4 pb-4 text-center">No record found</td>
                                                                    </tr>
                                                                </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </form>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateEnterprises: bindActionCreators(updateEnterprises, dispatch),
    getEnterpriseUser: bindActionCreators(getEnterpriseUser, dispatch),
    deleteUser: bindActionCreators(deleteUser, dispatch),
    uploadUser: bindActionCreators(uploadUser, dispatch),



});
export default connect(mapStateToProps, mapDispatchToProps)(Edit);
