import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getAllResources, deleteResources, getAllConditions } from '../../actions/resources';
import { Box } from "@material-ui/core";
import parse from "html-react-parser";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import AddResources from './add';
import EditResources from './edit';
import Swal from 'sweetalert2';
import { getAllTags } from '../../actions/tags';
import { getAllCategories } from '../../actions/categories';
import { USER_PORTAL_URL } from "../../config";

const Resources = (props) => {
    document.title = "Resources";
    const [resources, setResources] = useState([]);
    const [selectedResources, setSelectedResources] = useState({});
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState("");
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);

    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    //set the search input keyword
    const onChangeSearchInput = (searchValue, isClearSearch = false) => {
        setSearchInput(searchValue);
    }

    const removeExtraSpace = (s) => s.replace(/(&nbsp;)*/g, '');


    const getResources = (clearSearch = false) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: 10,
            is_admin: true,
        }
        if (!clearSearch && searchInput && searchInput != '') {
            params = { ...params, search: searchInput, page: 1, };
        }
        setLoading(true)
        props.getAllResources(params, (res) => {
            setLoading(false);
            if (res.status) {
                let resources = res.data.data;
                setResources(resources);
                setTotalPages(res.data.total_pages)
            }
            else {
                toast.error(res.message);
                setResources([])
                setTotalPages("")
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }



    //get id of selected resources
    const editResourcesDetails = (resourcesId) => {
        let selectedResources = resources.find(item => item.id == resourcesId);
        setSelectedResources(selectedResources);

        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Resources' });
            setKey(Math.random());
        }, 200);
    }

    // Delete selected Resources
    const deleteResources = (resourcesId) => {
        Swal.fire({
            title: 'Are you sure want to remove this Resources?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    selectedResourcesId: resourcesId,
                    token: props.auth.token
                }
                props.deleteResources(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getResources();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };

    const getTags = () => {
        let params = {
            token: props.auth.token,
            page: 1,
            limit: 10
        }
        props.getAllTags(params, (res) => {

            if (res.status) {
                let tags = res.data.tags;
                let tagsToOptions = tags.map((tags, index) => {
                    return {
                        value: tags.id,
                        label: tags && tags.name ? tags.name : "",
                    }
                })
                setTags(tagsToOptions);
            }
            else {
                toast.error(res.message);
            }
        },
            (err) => {
                toast.error(err.message);
            })
    }

    const getAllCategories = () => {
        let params = {
            token: props.auth.token,
            page: 1,
            limit: 10,
            is_admin: true,
        }
        props.getAllCategories(params, (res) => {
            if (res.status) {
                let categories = res.data.categories;
                let categoriesToOptions = categories.map((categories, index) => {
                    return {
                        value: categories.id,
                        label: categories && categories.name ? categories.name : "",
                    }
                })
                setCategories(categoriesToOptions);
            }
            else {
                toast.error(res.message);
            }
        },
            (err) => {
                toast.error(err.message);
            })
    }

    const copyResourceLink = (resourceId) => {
        const url = USER_PORTAL_URL + '/resource/detail/' + resourceId;
        navigator.clipboard.writeText(url); 
        toast.success("Resource URL copied to clipboard!")
    }

    useEffect(() => {
        getResources();
        getTags();
        getAllCategories();
    }, [page,searchInput]);


    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-6">
                                <h1 className="m-0">Resources &nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Resources' }); setKey(Math.random()); }}
                                    >
                                        <i className="fas fa-plus"></i> Add Resources</button>
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-6 text-end" >
                                <form className='inputSearchForm'>
                                    <div className='input-group me-2'>
                                        <input type="text" className="form-control rounded" onChange={(e) => onChangeSearchInput(e.target.value)} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                        {/* <button className='inputClose' onClick={() => { onChangeSearchInput(""); getResources(true) }} type="reset"> <i className="las la-times"></i> </button> */}
                                    </div>
                                    <button className='searchBtn' type="button" onClick={() => { return searchInput ? getResources() : '' }}>Search</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <>
                                            <div className="row p-5 mt-5 mb-5">
                                                <div className="col-12 text-center">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        : (
                                            <>
                                                <table style={{ width: '100%' }} className="table table-striped">
                                                    <thead style={{ fontSize: '15px' }}>
                                                        <tr>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Serial No</th>
                                                            <th style={{ borderTop: '0px' }}>Title</th>
                                                            <th style={{ borderTop: '0px' }}>Content</th>
                                                            <th style={{ borderTop: '0px' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: '15px' }}>
                                                        {resources && resources.length > 0 ?
                                                            <>
                                                                {resources && resources.map((resources, index) =>
                                                                    <tr key={index} >
                                                                        <td className='pl-3'>{(page - 1) * 10 + index + 1}</td>
                                                                        <td>{resources.title.length > 50 ? resources.title.substring(0, 50) + " ..." : resources.title}</td>
                                                                        <td>{resources.content.length > 50 ? parse(removeExtraSpace(resources.content.substring(0, 50) + " ...")) : parse(removeExtraSpace(resources.content))}</td>
                                                                        <td style={{textAlign: "center"}}>
                                                                            <button className='btn btn-sm btn-del-key internal m-1'
                                                                                title='Copy Resource URL'
                                                                                onClick={() => { copyResourceLink(resources.id) }}
                                                                                style={{ background: '#ffc107', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-copy'></i>
                                                                                </span>
                                                                            </button>
                                                                            <button className='btn btn-sm btn-del-key internal m-1'
                                                                                title='Edit'
                                                                                onClick={() => { editResourcesDetails(resources.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-pencil-alt'></i>
                                                                                </span>
                                                                            </button>
                                                                            <button className='btn btn-sm btn-del-key internal m-1'
                                                                                title='Delete'
                                                                                onClick={() => { deleteResources(resources.id) }}
                                                                                style={{ background: '#dc3545', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-trash'></i>
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <tr>
                                                                    <td colSpan='4' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        key={page}
                                                        //count={totalPages}
                                                        count={totalPages || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddResources closeModal={() => handleOnCloseModal()} refreshResources={() => { getResources() }} tags={tags} categories={categories} />
                    : <EditResources closeModal={() => handleOnCloseModal()} selectedResources={selectedResources} refreshResources={() => { getResources() }} tags={tags} categoriesToOptions={categories} />

                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />

        </>

    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllResources: bindActionCreators(getAllResources, dispatch),
    deleteResources: bindActionCreators(deleteResources, dispatch),
    getAllTags: bindActionCreators(getAllTags, dispatch),
    getAllCategories: bindActionCreators(getAllCategories, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(Resources);
