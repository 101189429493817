import React, { useState,useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { updateDosageTypes } from '../../actions/dosageTypes';
import Button from 'react-bootstrap/Button';


const EditDosageTypes = (props) => {
    const selectedDosageTypes = props.selectedDosageTypes;
    const [dosageTypesName, setDosageTypesName] = useState(selectedDosageTypes ? selectedDosageTypes.name : "");
    const [description, setDescription] = useState(selectedDosageTypes ? selectedDosageTypes.description : "");
    const [loading, setLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "DosageTypesName") {
            setDosageTypesName(value.trimStart());
        }
        else if (fieldName === "description") {
            setDescription(value.trimStart());
        }
      };
    
 
    const handleUpdate = () => {
        if (!dosageTypesName) {
            toast.error("Please enter dosageTypes Name");
            return;
            } 
            else if (!description) {
            toast.error("Please enter description");
            return;
        }

        let params = {
            token: props.auth.token,
            name: dosageTypesName,
            description : description,
            selectedDosageTypesId: selectedDosageTypes ? selectedDosageTypes.id : "" ,
        }
        
        setLoading(true);
        props.updateDosageTypes(params, res => {
            setLoading(false);
          if( res.status){
             toast.success(res.message);
             props.refreshDosageTypes();
             props.closeModal();
          }
          else {
            toast.error(res.message);
          }
            },
            err => {
            setLoading(false);
            toast.error("Something went wrong!");
        })
    
    };

    useEffect(() => {
        //
      }, [])

    return (
        <> 
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Dosage Types</label>
                        <input className="form-control" id="inputName" type="DosageTypesName" value={dosageTypesName} placeholder="DosageTypes"
                        
                            name="DosageTypesName" required="" onChange={(e) => {onChangeInput(e, 'DosageTypesName')}} />
                    </div>
                    <div className="form-group mt-3 mb-3 col-md-12">
                        <label className="label_text" htmlFor="inputFirstName">Description</label>
                        <input className="form-control" id="inputName" type="description" value={description} placeholder="Description"
                        
                            name="description" required="" onChange={(e) => {onChangeInput(e, 'description')}} />
                    </div>
                    <div className="form-group text-center col-md-12 mt-3 mb-3">
                        <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                            <span>Close</span>
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-primary" disabled={loading} type="button" onClick={() =>{handleUpdate()}}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save Changes</span>
                        </button>
                    </div>
            </form>
         
        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateDosageTypes: bindActionCreators(updateDosageTypes, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDosageTypes);
