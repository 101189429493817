import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { updateTagsTypes } from "../../actions/tags";


const EditTags = (props) => {
    const selectedTagsTypes = props.selectedTagsTypes;
    const [tagsTypesName, setTagsTypesName] = useState(selectedTagsTypes ? selectedTagsTypes.name : "");
    const [loading, setLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "TagsTypesName") {
            setTagsTypesName(value.trimStart());
        }
    };

    //edit tags
    const handleUpdate = () => {
        if (!tagsTypesName) {
            toast.error("Please enter tag Name");
            return;
        }

        let params = {
            token: props.auth.token,
            name: tagsTypesName,
            selectedTagId: selectedTagsTypes ? selectedTagsTypes.id : "",
        }

        setLoading(true);
        props.updateTagTypes(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshTags();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
        //
    }, [])

    return (
        <>
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Tag Name</label>
                    <input className="form-control" id="inputName" type="TagsTypesName" value={tagsTypesName} placeholder="Tags Types"
                        name="TagsTypesName" required="" onChange={(e) => { onChangeInput(e, 'TagsTypesName') }} />
                </div>
                <div className="form-group text-center col-md-12 mt-3 mb-3">
                    <button className="btn btn-secondary" type="button" onClick={() => { props.closeModal() }}>
                        <span>Close</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={loading} type="button" onClick={() => { handleUpdate() }}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Save Changes</span>
                    </button>
                </div>
            </form>

        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateTagTypes: bindActionCreators(updateTagsTypes, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditTags);
