import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL, AUTH_API_URL } from "../../config";
import { logout } from '../../../redux/actions/auth';

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {
  let responseMessage = defaultErrorMessage;
  let payload = response.payload;  // Data returned by API

  if (payload && Array.isArray(payload) && payload.length > 0) {
    responseMessage = payload[0];
  }
  else if (response.msg) {
    responseMessage = response.msg;
  }
  return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
  let res = {};
  if (result.status === 200 || result.status === 201){
    res = {
      status: true,
      message: responseMessage,
      statusCode: result.status,
      type: message.success,
      data: result.data.payload
    };
  } else {
    res = {
      status: false,
      message: responseMessage,
      type: message.error,
      statusCode: result.status
    };
  }
  return res;
}

/****** action creator for login ********/
export const userLogin = (params, cb) => {
  return dispatch => {
    //let url = `/login`;
    RestClient.post(`${AUTH_API_URL}/users/login`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for forgot password ********/
export const forgotPassword = (params, cb) => {
  return dispatch => {
    //let url = `/login`;
    RestClient.post(`${AUTH_API_URL}/users/forgot-password`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for reset password ********/
export const resetPassword = (params, cb) => {
  return dispatch => {
    //let url = `/login`;
    RestClient.post(`${AUTH_API_URL}/users/validate-password`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for logout user ********/
export const userLogOut = (params, cb) => {
  // // Removing user's data from redux. Later we will do it using API
  // return dispatch => {
  //   let res = {
  //     status: true,
  //     message: "Logged out successfully!",
  //     statusCode: 200,
  //     type: message.success,
  //     data: {}
  //   };
  //   dispatch(logout());
  //   cb(res);
  // }

  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/users/logout`, params)
      .then(result => {
        dispatch(logout());
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        dispatch(logout());
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for get All users ********/
export const getAllUsers = (params, cb) => {
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/users`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};



/****** action creator for get single users ********/
export const getUserDetails = (params, cb) => {
  let id = params.id;
  delete params.id;
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/users/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for get All users ********/
export const getDashboardData = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/dashboards/get-all-data`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

