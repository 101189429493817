import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgotPassword } from "../../actions/user";

const ForgotPassword = (props) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: ""
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({...formData, [name]: value });
        setErrorMessages({...errorMessages, [name]: "" });
    }

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
  
        if (!formData.email) {
          formIsValid = false;
          errors = {...errors, email: "Please enter your email."};
        } 
        else if(typeof formData.email !== "undefined"){
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email.trim())) {
              formIsValid = false;
              errors = {...errors, email: "Please enter valid email."};
            }
        }
  
        setErrorMessages(errors);
        return formIsValid;
      }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(validateForm()){
            setLoading(true);

            let params = {
                type: "email",
                email: formData.email.trim()
            }
            props.forgotPassword(params, res => {
                    setLoading(false);
                    if(res.status){
                        toast.success(res.message);
                        history.push('/login');
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }
        
    };

    useEffect(() => {
        // 
    }, []);

    return (
        <>
            <div className="card-header text-center">
                <h3 className='mb-0'><b>Forgot</b> Password</h3>
            </div>
            <div className="card-body login-card-body">
                <p className="login-box-msg">Enter your email and we´ll send you instructions on how to reset your password</p>
                
                <form onSubmit={(e) => {handleSubmit(e)}}>
                    <div className="input-group mb-3">
                        <input 
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder='Email Address'
                            value={formData.email}
                            onChange={(e) => {handleChange(e)}}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope"></span>
                            </div>
                        </div>
                        <span className="error invalid-feedback">{errorMessages.email}</span>
                    </div>

                    <div className="row">
                        <div className="col-7">
                            <a onClick={() => {history.push('/login')}}>Back to Login?</a>
                        </div>
                        
                        <div className="col-5">
                            <button className="btn btn-primary btn-block" disabled={loading} type="submit">
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Reset</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    forgotPassword: bindActionCreators(forgotPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);