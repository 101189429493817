import * as TYPE from './types'

export const loginSuccess = (payload) => {
    return {
        type:TYPE.LOGIN_SUCCESS,
        payload: payload
    }
}

export const logout = () => {
    return {
        type:TYPE.LOGOUT
    }
}