import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { addNewDosageTypes } from '../../actions/dosageTypes';

const AddDosageTypes = (props) => {
    const [dosageTypesName, setDosageTypesName] = useState("");
    const [description, setDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "dosageTypesName") {
            setDosageTypesName(value.trimStart());
        }
        else if (fieldName === "description") {
            setDescription(value.trimStart());
        }
    };

    //add new dosageTypes
    const handleSubmit = () => {
        if (!dosageTypesName) {
            toast.error("Please enter dosageTypes name");
            return;
        }
        else if (!description) {
            toast.error("Please enter description");
            return;
        }

        let params = {
            token: props.auth.token,
            name: dosageTypesName,
            description: description,
        }

        setIsLoading(true);
        props.addNewDosageTypes(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshDosageTypes();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
        //
    }, [])


    return (
        <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
            <div className="form-group mt-3 mb-3 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">DosageTypes Name</label>
                <input className="form-control" id="inputName" type="text" value={dosageTypesName} placeholder="DosageTypes Name"
                    name="DosageTypes Name" required="" onChange={(e) => { onChangeInput(e, 'dosageTypesName') }} />
            </div>
            <div className="form-group mt-3 mb-3 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Description</label>
                <input className="form-control" id="inputName" type="text" value={description} placeholder="Description"

                    name="description" required="" onChange={(e) => { onChangeInput(e, 'description') }} />
            </div>
            <div className="form-group text-center col-md-12 mt-3 mb-3">
                <button className="btn btn-secondary" type="button" onClick={() =>{props.closeModal()}}>
                    <span>Close</span>
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() =>{handleSubmit()}}>
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Submit</span>
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
   addNewDosageTypes: bindActionCreators(addNewDosageTypes, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDosageTypes);