import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { CKEditor } from 'ckeditor4-react';
import { getAllSettings, updateSettings } from "../../actions/settings";

const AddSettings = (props) => {
    document.title = "Settings";
    const [allSettings, setAllSettings] = useState({});
    const [aboutUs, setAboutUs] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState("");
    const [termsAndCondition, setTermsAndCondition] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [heartRateMin, setHeartRateMin] = useState("");
    const [heartRateMax, setHeartRateMax] = useState("");
    const [bodyTempMin, setBodyTempMin] = useState("");
    const [bodyTempMax, setBodyTempMax] = useState("");
    const [oxygenMin, setOxygenMin] = useState("");
    const [oxygenMax, setOxygenMax] = useState("");
    const [sbpMin, setsbpMin] = useState("");
    const [sbpMax, setsbpMax] = useState("");
    const [dbpMin, setdbpMin] = useState("");
    const [dbpMax, setdbpMax] = useState("");


    //input onchange heartRate, bodyTemp, sbp, dbp, oxygen
    const onChangeInput = (e, fieldName) => {
        let value = e.target.value;
        value = value.trim()
        if (fieldName === "heartRateMin") {
            setHeartRateMin(value);
        }
        else if (fieldName === "heartRateMax") {
            setHeartRateMax(value);
        }
        else if (fieldName === "bodyTempMin") {
            setBodyTempMin(value);
        }
        else if (fieldName === "bodyTempMax") {
            setBodyTempMax(value);
        }
        else if (fieldName === "oxygenMin") {
            setOxygenMin(value);
        }
        else if (fieldName === "oxygenMax") {
            setOxygenMax(value);
        }
        else if (fieldName === "sbpMin") {
            setsbpMin(value);
        }
        else if (fieldName === "sbpMax") {
            setsbpMax(value);
        }
        else if (fieldName === "dbpMin") {
            setdbpMin(value);
        }
        else if (fieldName === "dbpMax") {
            setdbpMax(value);
        }

    };

    //get all settings Data
    const getSettings = () => {
        let params = {
            token: props.auth.token,
        }
        setLoading(true);
        props.getAllSettings(params, res => {
            setLoading(false);
            if (res.status) {
                let allSettingData = res.data;
                setAllSettings(allSettingData);
                setAboutUs(res.data?.about);
                setPrivacyPolicy(res.data?.privacy_policy);
                setTermsAndCondition(res.data?.terms_and_conditons);
                setHeartRateMin(res.data?.vital_stats_parameters?.heart_rate?.min)
                setHeartRateMax(res.data?.vital_stats_parameters?.heart_rate?.max)
                setBodyTempMin(res.data?.vital_stats_parameters?.body_temperature?.min)
                setBodyTempMax(res.data?.vital_stats_parameters?.body_temperature?.max)
                setOxygenMin(res.data?.vital_stats_parameters?.oxygen?.min)
                setOxygenMax(res.data?.vital_stats_parameters?.oxygen?.max)
                setsbpMin(res.data?.vital_stats_parameters?.blood_pressure?.sbp?.min)
                setsbpMax(res.data?.vital_stats_parameters?.blood_pressure?.sbp?.max)
                setdbpMin(res.data?.vital_stats_parameters?.blood_pressure?.dbp?.min)
                setdbpMax(res.data?.vital_stats_parameters?.blood_pressure?.dbp?.max)
            }
            else {

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    //form submit
    const handleUpdate = (id) => {
        let params = {
            token: props.auth.token,
            settingsId: allSettings.id,
        }
        //console.log("id",id);
        if (id === 1) {
            if (!aboutUs) {
                toast.error("Please enter aboutUs")
                return;
            }
            params = {
                ...params, about: aboutUs,
            }
        }
        else if (id === 2) {
            if (!privacyPolicy) {
                toast.error("Please enter privacyPolicy")
                return;
            }
            params = {
                ...params, privacy_policy: privacyPolicy,
            }
        }
        else if (id === 3) {
            if (!privacyPolicy) {
                toast.error("Please enter termsAndCondition")
                return;
            }
            params = {
                ...params, terms_and_conditons: termsAndCondition,
            }
        }
        else if (id === 4) {
            if (!heartRateMin) {
                toast.error("Please enter heart rate min")
                return;
            }
            else if (!heartRateMax) {
                toast.error("Please enter heart rate max")
                return;
            }
            else if (!oxygenMin) {
                toast.error("Please enter oxygen min")
                return;
            }
            else if (!oxygenMax) {
                toast.error("Please enter oxygen max")
                return;
            }
            else if (!bodyTempMin) {
                toast.error("Please enter body temp min")
                return;
            }
            else if (!bodyTempMax) {
                toast.error("Please enter body temp max")
                return;
            }
            else if (!sbpMin) {
                toast.error("Please enter blood pressure sbp min")
                return;
            }
            else if (!sbpMax) {
                toast.error("Please enter blood pressure sbp max")
                return;
            }
            else if (!dbpMin) {
                toast.error("Please enter blood pressure dbp min")
                return;
            }
            else if (!dbpMax) {
                toast.error("Please enter blood pressure dbp max")
                return;
            }
            params = {
                ...params,
                vital_stats_parameters: {
                    heart_rate: {
                        min: heartRateMin,
                        max: heartRateMax,
                    },
                    oxygen: {
                        min: oxygenMin,
                        max: oxygenMax,
                    },
                    body_temperature: {
                        min: bodyTempMin,
                        max: bodyTempMax,
                    },
                    blood_pressure: {
                        sbp: {
                            min: sbpMin,
                            max: sbpMax,
                        },
                        dbp: {
                            min: dbpMin,
                            max: dbpMin,
                        }
                    }
                }
            }
        }
        //console.log("params",params);
        setIsLoading(true);
        props.updateSettings(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                getSettings();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };

    // validate maxlength of the input field
    const inputHandler = (e) => {
        const { value, maxLength } = e.target;
        if (String(value).length >= maxLength) {
            e.preventDefault();
            return;
        }
    };

    useEffect(() => {
        getSettings();
    }, [])

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12 col-md-6">
                            <h1 className="m-0">Settings &nbsp;&nbsp;
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">
                    <div className="col-12">
                        <div className="card ms-2">
                            <div className="card-body p-0 ">
                                {loading ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <form className="form_horizontal row ms-2 mr-2" role="form" >

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group mt-2 col-md-12">
                                                        <label className="label_text" htmlFor="inputPassword">Page Settings</label>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <div className="heaert">
                                                            <label className="col-sm-2 col-form-label">Heart Rate: </label>
                                                            <input type="number" placeholder="min" step="0.01" onKeyPress={inputHandler} maxLength="5" value={heartRateMin} name="heartRateMin" onChange={(e) => { onChangeInput(e, 'heartRateMin') }} className="form-control" id="inputPassword" />
                                                            <input type="number" placeholder="max" onKeyPress={inputHandler} maxLength="5" value={heartRateMax} name="heartRateMax" onChange={(e) => { onChangeInput(e, 'heartRateMax') }} className="form-control" id="inputPassword2" />
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <div className="heaert">
                                                            <label className="col-sm-2 col-form-label bodytemp">Body Temp: </label>
                                                            <input type="number" placeholder="min" onKeyPress={inputHandler} maxLength="5" value={bodyTempMin} name="bodyTempMin" onChange={(e) => { onChangeInput(e, 'bodyTempMin') }} className="form-control" id="inputPassword" />
                                                            <input type="number" placeholder="max" onKeyPress={inputHandler} maxLength="5" value={bodyTempMax} name="bodyTempMax" onChange={(e) => { onChangeInput(e, 'bodyTempMax') }} className="form-control" id="inputPassword2" />
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <div className="heaert">
                                                            <label className="col-sm-2 col-form-label">Oxygen: </label>
                                                            <input type="number" placeholder="min" onKeyPress={inputHandler} maxLength="5" value={oxygenMin} name="oxygenMin" onChange={(e) => { onChangeInput(e, 'oxygenMin') }} className="form-control" id="inputPassword" />
                                                            <input type="number" placeholder="max" onKeyPress={inputHandler} maxLength="5" value={oxygenMax} name="oxygenMax" onChange={(e) => { onChangeInput(e, 'oxygenMax') }} className="form-control" id="inputPassword2" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col blood">
                                                    <div className="form-group mt-2 col-md-12">
                                                        <label className="label_text" htmlFor="inputPassword">Blood Pressure</label>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <div className="heaert2">
                                                            <label className="col-sm-2 col-form-label bp">S B P: </label>
                                                            <input type="number" placeholder="min" onKeyPress={inputHandler} maxLength="5" value={sbpMin} name="sbpMin" onChange={(e) => { onChangeInput(e, 'sbpMin') }} className="form-control" id="inputPassword" />
                                                            <input type="number" placeholder="max" onKeyPress={inputHandler} maxLength="5" value={sbpMax} name="sbpMax" onChange={(e) => { onChangeInput(e, 'sbpMax') }} className="form-control" id="inputPassword2" />
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <div className="heaert2">
                                                            <label className="col-sm-2 col-form-label bp">D B P: </label>
                                                            <input type="number" placeholder="min" onKeyPress={inputHandler} maxLength="5" value={dbpMin} name="dbpMin" onChange={(e) => { onChangeInput(e, 'dbpMin') }} className="form-control" id="inputPassword" />
                                                            <input type="number" placeholder="max" onKeyPress={inputHandler} maxLength="5" value={dbpMax} name="dbpMax" onChange={(e) => { onChangeInput(e, 'dbpMax') }} className="form-control" id="inputPassword2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group  col-md-12 ">
                                                &nbsp;
                                                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleUpdate(4) }}>
                                                    {isLoading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    &nbsp;
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="card ms-2">
                            <div className="card-body p-0 ">
                                {loading ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <form className="form_horizontal row ms-2 mr-2" role="form" >
                                            <div className="form-group mt-2 col-md-12">
                                                <label className="label_text" htmlFor="inputPassword">About Us</label>
                                                {aboutUs ?
                                                    <CKEditor
                                                        initData={aboutUs}
                                                        config={{
                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", 'Table', "image"],
                                                        }}
                                                        name='editor'
                                                        readOnly={false}
                                                        onChange={(event, editor) => {
                                                            const data = event.editor.getData()
                                                            setAboutUs(data);
                                                        }}
                                                        type="classic"
                                                        onBeforeLoad = { ( CKEDITOR ) => CKEDITOR.on( 'dialogDefinition', (event) => {
                                                            setTimeout(() => {
                                                                document.querySelector('.modal.show').removeAttribute('tabindex');
                                                            }, 500);
                                                        })}
                                                    />
                                                    :
                                                    <>
                                                        {/* <CKEditor
                                                            initData={aboutUs}
                                                            config={{ toolbar: editorToolbar }}config={{
                                                                removePlugins: [ "EasyImage","ImageUpload","MediaEmbed",'Table',"image"],
                                                            }}  name='editor'
                                                            readOnly={false}
                                                            onChange={(event, editor) => {
                                                                const data = event.editor.getData()
                                                                setAboutUs(data);
                                                            }}
                                                            type="classic"
                                                        /> */}
                                                    </>
                                                }
                                            </div>
                                            <div className="form-group  col-md-12">
                                                &nbsp;
                                                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleUpdate(1) }}>
                                                    {isLoading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    &nbsp;
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                            <div className="form-group mt-2 col-md-12">
                                                <label className="label_text" htmlFor="inputPassword">Privacy Policy</label>
                                                {privacyPolicy ?
                                                    <CKEditor
                                                        initData={privacyPolicy}
                                                        config={{
                                                            removePlugins: [ "EasyImage","ImageUpload","MediaEmbed",'Table',"image"],
                                                          }}
                                                        name='editor'
                                                        readOnly={false}
                                                        onChange={(event, editor) => {
                                                            const data = event.editor.getData()
                                                            setPrivacyPolicy(data);
                                                        }}
                                                        type="classic"
                                                        onBeforeLoad = { ( CKEDITOR ) => CKEDITOR.on( 'dialogDefinition', (event) => {
                                                            setTimeout(() => {
                                                                document.querySelector('.modal.show').removeAttribute('tabindex');
                                                            }, 500);
                                                        })}
                                                    />
                                                    :
                                                    <>
                                                        {/* <CKEditor
                                                            initData={privacyPolicy}
                                                            config={{
                                                                removePlugins: [ "EasyImage","ImageUpload","MediaEmbed",'Table',"image"],
                                                            }}
                                                            name='editor'
                                                            readOnly={false}
                                                            onChange={(event, editor) => {
                                                                const data = event.editor.getData()
                                                                setPrivacyPolicy(data);
                                                            }}
                                                            type="classic"
                                                        /> */}
                                                    </>

                                                }
                                            </div>
                                            <div className="form-group col-md-12 ">
                                                &nbsp;
                                                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleUpdate(2) }}>
                                                    {isLoading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    &nbsp;
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                            <div className="form-group mt-2 col-md-12">
                                                <label className="label_text" htmlFor="inputPassword">Terms and conditions</label>
                                                {termsAndCondition ?
                                                    <CKEditor
                                                        initData={termsAndCondition}
                                                        config={{
                                                            removePlugins: [ "EasyImage","ImageUpload","MediaEmbed",'Table',"image"],
                                                          }}
                                                        name='editor'
                                                        readOnly={false}
                                                        onChange={(event, editor) => {
                                                            const data = event.editor.getData()
                                                            setTermsAndCondition(data);
                                                        }}
                                                        type="classic"
                                                        onBeforeLoad = { ( CKEDITOR ) => CKEDITOR.on( 'dialogDefinition', (event) => {
                                                            setTimeout(() => {
                                                                document.querySelector('.modal.show').removeAttribute('tabindex');
                                                            }, 500);
                                                        })}
                                                    />
                                                    :
                                                    <>
                                                        {/* <CKEditor
                                                            initData={termsAndCondition}
                                                            config={{
                                                                removePlugins: [ "EasyImage","ImageUpload","MediaEmbed",'Table',"image"],
                                                             }}
                                                            name='editor'
                                                            readOnly={false}
                                                            onChange={(event, editor) => {
                                                                const data = event.editor.getData()
                                                                setTermsAndCondition(data);
                                                            }}
                                                            type="classic"
                                                        /> */}
                                                    </>

                                                }
                                            </div>
                                            <div className="form-group  col-md-12 ">
                                                &nbsp;
                                                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleUpdate(3) }}>
                                                    {isLoading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    &nbsp;
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    updateSettings: bindActionCreators(updateSettings, dispatch),
    getAllSettings: bindActionCreators(getAllSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSettings);
