import React from 'react';
import { useHistory } from 'react-router-dom';
//import BrandLogo from "../../public/images/footer-logo.svg";
import BrandLogo from "../../public/images/brand-logo.png";

const Sidebar = () => {
    const history = useHistory();
    const pathName = window.location.pathname;

    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a onClick={() => { history.push('/') }} className="brand-link">
                    {/* <span className="brand-text font-weight-bold">Shepherd</span> */}
                    <span className="first-letter img-circle elevation-3 ml-3">S</span>
                    <img src={BrandLogo} className="brand-text font-weight-light" alt="Brand Logo" style={{ width: "74px" }} />
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
                            <li className="nav-item">
                                <a onClick={() => { history.push('/') }} className={['/'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Dashboard</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/careRole') }} className={['/careRole'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-tie"></i>
                                    <p>Care Role</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/user') }} className={['/user'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-circle"></i>
                                    <p>User</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/medicalConditions') }} className={['/medicalConditions'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-plus-square"></i>
                                    <p>Medical Conditions</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/lockBoxes') }} className={['/lockBoxes'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa fa-file"></i>
                                    <p>Document Types </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/resources') }} className={['/resources'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa fa-window-restore"></i>
                                    <p>Resources </p>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a onClick={() => { history.push('/dosageTypes') }} className={['/dosageTypes'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa fa-h-square"></i>
                                    <p>Dosage Types </p>
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a onClick={() => { history.push('/medlist') }} className={['/medlist'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fa fa-medkit"></i>
                                    <p>Medlist </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/categories') }} className={['/categories'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-list-alt"></i>
                                    <p>Categories </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/tags') }} className={['/tags'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-tags	"></i>
                                    <p>Tags </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/settings') }} className={['/settings'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fa fa-cog"></i>
                                    <p>Settings</p>
                                </a>
                            </li>
                            
                            <li className="nav-item">
                                <a onClick={() => { history.push('/enterprises') }} className={['/enterprises'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fa fa-business-time"></i>
                                    <p>Enterprises </p>
                                </a>
                            </li>

                            {/* <li className={['/reports', '/'].includes(pathName) ? "nav-item has-treeview menu-open selected" : "nav-item has-treeview"}>
                                <a href="/" className="nav-link">
                                    <i className="nav-icon fas fa-chart-pie" />
                                    <p>
                                        Reports
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item ml-3">
                                        <a className={pathName == '/' ? "nav-link active" : "nav-link" } onClick={() => {history.push('/')}}>
                                            <i className="nav-icon fas fa-tachometer-alt" />
                                            <p className=''>Dashboard</p>
                                        </a>
                                    </li>
                                    <li className="nav-item ml-3">
                                        <a className={pathName == '/reports' ? "nav-link active" : "nav-link" } onClick={() => {history.push('/reports')}}>
                                            <i className="nav-icon fas fa-chart-bar" />
                                            <p className=''>Reports</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>  */}
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}

export default Sidebar