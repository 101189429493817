const Message = {
  emptyField: 'Please Enter Value',
  success: 'Success',
  error: 'Error!',
  commonError: 'Something went wrong!',
  logout: 'Logout!',
  requiredField: name => `${name} is required!`,
  validEmail: 'Please enter valid email.',
  confirmLogout: 'Do you really want to logout?'
};

module.exports = Message;
